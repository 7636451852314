// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'laytrip',
  stage: 'uat',
  code: 'LT',
  version: 7,
  // apiUrl:'http://localhost:4040/',
  google_places_api_key: "AIzaSyB7Ws9zJ9ozVruSjF2N3pDVsqHF-h1QtBU",
  apiUrl:'https://api.adminredesign.laytrip.com/',
  //apiUrl:'https://api.laytrip.com/',
  s3BucketUrl: 'https://d6y4gjdvady05.cloudfront.net/',
  fb_api_key:'933948490440237',
  freshChatToken:'dd6dc59e-c42a-4f79-8d30-c58c1ea2c51b',
  google_client_id:'154754991565-9lo2g91remkuefocr7q2sb92g24jntba.apps.googleusercontent.com',
  siteUrl: 'https://uat.laytrip.com',
  VAPID_PUBLIC_KEY:'BLqKtvo8fc7ZHJ5m2j4RYbAoJJ6WtdUYNH38ZaStSIuecp1sCcrE7CFkFT266FCpeky4nBR5_yNgxygoLqE3jJ4',
  googleTranslationKey: 'AIzaSyAM2IBT7FXhbv1NFqqVEdYkFDTyqPUhmR8',
  recaptcha_secret_key: "6LcPUboZAAAAAN1Sh411ruqdmT4OD6ZDe65rTsVO",
  clientId: 'com.laytripweb',
  cryptoKey: 'H4rdT0Gu3ssP@ssw0rd!'
};